import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    <span
      data-controller="mail-to"
      data-mail-to-user-value="user"
      data-mail-to-domain-value="example.com"
      data-mail-to-text-value="Contact Us"
      data-mail-to-subject-value="Hello"
      data-mail-to-body-value="I'm contacting you about..."
      data-mail-to-cc-value="cc@example.com"
      data-mail-to-bcc-value="bcc@example.com"
      data-mail-to-reply-to-value="reply@example.com">
    </span> 
  */
  static values = {
    user: String,
    domain: String,
    text: String,
    subject: String,
    body: String,
    cc: String,
    bcc: String,
    replyTo: String,
    classList: String,
    linkTarget: String,
  }

  connect() {
    this.createEmailLink()
  }

  createEmailLink() {
    const email = `${this.userValue}@${this.domainValue}`
    const linkText = this.hasTextValue ? this.textValue : email

    // Create a params object to hold email query parameters
    const params = new URLSearchParams()

    if (this.hasSubjectValue) params.append('subject', this.subjectValue)
    if (this.hasBodyValue) params.append('body', this.bodyValue)
    if (this.hasCcValue) params.append('cc', this.ccValue)
    if (this.hasBccValue) params.append('bcc', this.bccValue)
    if (this.hasReplyToValue) params.append('reply-to', this.replyToValue)

    const queryString = params.toString()
    const mailtoLink = queryString
      ? `mailto:${email}?${queryString}`
      : `mailto:${email}`

    const link = document.createElement('a')
    link.href = mailtoLink
    link.textContent = linkText
    if (this.hasClassListValue) {
      link.classList = this.classListValue
    }
    if (this.hasLinkTargetValue) {
      link.target = this.linkTargetValue
    }

    this.element.appendChild(link)
  }
}
