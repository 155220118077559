import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="color-scheme"
export default class extends Controller {
  static targets = ['switcher']

  connect() {
    this.switcherTarget.dataset.currentTheme = localStorage.theme || 'dark'
  }

  toggle() {
    if (this.switcherTarget.dataset.theme === 'system') {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        localStorage.theme = 'dark'
      } else {
        localStorage.theme = 'light'
      }
    } else if (this.switcherTarget.dataset.theme === 'light') {
      localStorage.theme = 'light'
    } else if (this.switcherTarget.dataset.theme === 'dark') {
      localStorage.theme = 'dark'
    }
    this.initialize()
  }

  initialize() {
    if (
      localStorage.theme === 'dark' ||
      (!('theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }
}
